import React from "react";
import Layout from "../components/Layout";
import aboutMain from "../img/about-main.png";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section dark-background-section">
      <div className="container">
        <div className="content">
          <div className="columns">
          <div className="column" style={{display: "flex", position: "relative"}}>
          <div className="vertical-center">
            <div className="content has-text-centered-mobile">
          <h1>Thank you!</h1>
          <p style={{marginTop: "0.5rem"}}>Thank you for joining our small community! We will be in touch soon.</p>
          <a className="button" href="/" style={{marginTop: "1rem", fontSize: "1.2rem"}}>Back to home page</a>
          </div>
          </div>
          </div>
          <div className="column">
          <img style={{width: "80%", marginLeft: "10%"}} src={aboutMain} />
          </div>
          </div>
          </div>
      </div>
    </section>
  </Layout>
);
